.modal-content-detail .btnAdd{
        width: 100%;
}

.modal-content-detail  .deFlexGoTocart{
    padding: 10px 20px;
    align-items: center;
}

.modal-content-detail .deFlexCart{
    display: flex;
    gap: 1rem;
    align-items: center;
    margin: 0;
}