.InfoUserLoguedNav{
    display: flex;
    flex-direction: column;
    gap: 0;
}
.InfoUserLoguedNav strong{
    color: var(--text-color2);
    font-weight: 500;
    font-size: 15px;
}
.InfoUserLoguedNav span{
    color: var(--text-color2);
    font-size: 14px;
}
@media (max-width: 900px){
    .InfoUserLoguedNav strong{
        color: var(--blanco);
        font-weight: 500;
        font-size: 15px;
    }
    .InfoUserLoguedNav span{
        color: var(--blanco);
        font-size: 14px;
    }
}