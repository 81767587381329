.AuthContainer{
    padding: 60px 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    background-color: var(--blanco);
}
.AuthContainer img{
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 100%;
   
}

.logoAtuh{
    width: 100px;
}
.deFlexActiveLink{
    display: flex;
    gap: 1rem;
    background-color: var(--gris2);
    border-radius: 10rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.deFlexActiveLink a{
    color: var(--color1);
    padding: 6px 20px;
}
.deFlexActiveLink .activeLink {
    
    background-color: var(--color1)!important;
    border-radius: 20px;
    color: var(--blanco)!important;
}


