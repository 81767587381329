
.detail-contain{
    width: 100%;
    gap: 2rem;
    display: flex;
    
}
.detail{
    width: 100%;
    padding: 6% 13%;
    gap: 2rem;
    background-color: var(--blanco);
    min-height: 100vh;
}
#swiperDetail{
    display: flex;
    width: 34rem;
    height: 30rem;
    border-radius: 10px;
    overflow: hidden;
    
}
#swiperDetail img{
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
}
.imgDetail{
    display: flex;
    gap: 1rem;

}

.imgGrid{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.imgGrid img{
    width: 40vh;
    height: 28.7vh;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
}
.img {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    border-radius: 10px;
    cursor: pointer;
}

.custom-modal {
    background: rgba(0, 0, 0, 0.8);
   
  }
  
  .custom-modal img {
    width: 100%;
    height: 100%;
 
  }
  
  iframe {
    width: 100%;
    height: 30vh;
    border-radius: 10px;
   
}
hr{
  
    border: 0.3px solid #00000013;
    
}

.share{
    background-color: var(--gris);
    border: none;
    border: none;
    font-size: 17px;
    color: var(--text-color2);
    padding: 8px 9px;
    border-radius: 100%;
    cursor: pointer;
}
.favoritos-btn{
    background-color: var(--gris);
    border: none;
    border: none;
    font-size: 17px;
    color: var(--text-color2);
    padding: 8px 9px;
    border-radius: 100%;
    cursor: pointer;
}

.deFlexDetail{
    display: flex;
    justify-content: space-between;
    padding: 20px 0%;
    align-items: center;
    gap: 2rem;
}
.deFLexIcon{
    display: flex;
    justify-content: space-between;
    padding: 20px 0%;
    align-items: center;
    gap: 2rem;
}
.title {
    font-size: 25px;
    color: var(--text-color2);
}
.detail-contain p{
    color: var(--text-color2);
    white-space: pre-line;
    max-height: 8rem;
    min-height: 8rem;
    
    overflow-y: scroll;
}
.detail-contain p::-webkit-scrollbar{
    width: 7px;
    height: 7px;
    cursor: pointer;
 }
 
 .detail-contain p::-webkit-scrollbar-thumb{
     background-color: var(--color1);
     width: 10px;
     border-radius:20px ;
     cursor: pointer;
 }

.deColumn{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.price {
    font-size: 25px;
    color: var(--color1);
    font-weight: 700;
    margin-top: 1rem;
  
}
.precioTachadoDetail {
    text-decoration: line-through;
    color: var(--text-color2)!important;
    font-size: 25px;
    font-weight: 700;
    margin-top: 1rem;
  }
.titlecoment{
    color: var(--text-color2);
  
}

.wpp{
   background-color: rgb(30, 184, 30);
   color: var(--blanco);
   
   padding: 10px 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: none;
   font-size: 17px;
   cursor: pointer;
   box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.196);
   border-radius: 10rem;
   gap: 10px;
   width: 10rem;
}
.wpp img{
    width: 25px;
}
.textDetail{
    display: flex;
    flex-direction: column;
    gap: 1rem;
   
}
.back{
    background-color: var(--gris);
    border: none;
    padding: 7px 9px;
    border-radius: 100%;
    color: var(--text-color2);
    font-size: 17px;
   
}
.textDetail h4{
    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--text-color2);
    font-weight: 500;
}
@media (max-width: 1024px) {
    .deFLexIcon{
        gap: 1rem;
    }
    
    .detail-contain p{
        max-height: 100%;
        min-height:  100%;
    }
    .deFlexDetail{
        position: absolute ;
        z-index: 3;
        top: 0;
        width: 100%;
        padding: 0 20px;
    }
    #swiperDetail{
        display: flex;
        width: 100%;
        height: 50vh;
        border-radius: 0;
        overflow: hidden;
      
}
    #swiperDetail img{
      display: flex;
      width: 100%;
      border-radius: 0;
   
    }
   
    .detail{
       
        padding:   0;
    }
    .detail-contain{
        flex-direction: column;
        padding:  0 0 100px 0 ;
       
    }
    .textDetail{
        margin-top: -4rem;
        z-index: 1;
        border-radius: 1rem 1rem 0 0;
        background-color: var(--blanco);
        padding: 20px;
       
}
}
